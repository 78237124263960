<template>
    <div class="modal-dating-status-info">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="$translate('DATING_STATUS')" />
            <i @click="$emit('close')" class="zmdi zmdi-close" />
        </div>
        <div class="body">
            <template v-for="content in contents">
                <div class="key" v-html="content.key" :key="content.key"></div>
                <div class="value" v-html="content.value" :key="content.value"></div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalDatingStatusInfo',
    data: () => ({
        contents: [
            {
                key: '호감 받음O:',
                value: `소개 상대에게 <strong>호감</strong>을 받은 회원
                    <div class="badge">'호감받음' 기준</div>
                    <ul class="list">
                        <li>1. 대화방에서 보낸 프로필에서 '소개받을게요(수락)'한 회원</li>
                        <li>2. 보낸 소개팅 제안(하늘색 편지봉투)을 수락한 추천회원</li>
                        <li>3. 데일리에서 소개 요청한 회원</li>
                    </ul>`,
            },
            {
                key: '양쪽 호감:',
                value: `양쪽이 서로 호감을 표현했고, 둘 중 누구에게도 결제(소개팅권) 요청을 보내지 않은 양쪽 회원 (결제 대기 전 단계)
                    <div class="additional">(연락처를 공유 받아서, 결제 요청을 보내세요!)</div>`,
            },
            {
                key: '결제 대기:',
                value: `결제(소개팅권) 요청을 보냈지만, 아직 결제를 하지 않은 회원
                    <div class="additional">(최근 48시간 안에 보낸 결제 요청만 표시)</div>`,
            },
        ],
    }),
}
</script>
